<script>
import TemplateBase from '../../../molecules/Templates/TemplateBase';

export default defineNuxtComponent({
  components: { TemplateBase },
  props: {
    stepElement: {
      type: Object,
      required: false,
    },
    stepId: {
      type: Number,
      required: true,
    },
  },
});
</script>

<template>
  <TemplateBase
    :placeholder="__('Label file upload')"
    :element="stepElement"
    :step-id="stepId"
  >
    <template #templateContent>
      <div
        class="flex cursor-pointer space-y-4 rounded border-2 border-dashed border-athens-gray px-4 py-4.5"
      >
        <trailblazer-icon name="upload" />
        {{ __('Upload file(s) here') }}
      </div>
    </template>
  </TemplateBase>
</template>
